<template>
  <div class="textbox">
    <div class="bottombox">
      <div class="bottom_left">
        <div class="tabbox">
          <div class="tabtitle">载体信息</div>
          <div
            class="tabbtn"
            :class="i == 1 || i == 3 ? 'active' : ''"
            @click="gettags(1)"
          >
            - 载体详情
          </div>
          <div
            class="tabbtn"
            :class="i == 2 ? 'active' : ''"
            @click="gettags(2)"
          >
            - 载体图片
          </div>
          <div
            class="tabbtn"
            :class="i == 4 ? 'active' : ''"
            @click="gettags(4)"
          >
            - 设施图片
          </div>

          <div
            class="tabbtn"
            :class="i == 5 || i == 6 ? 'active' : ''"
            @click="gettags(5)"
          >
            - 载体联系人
            <span class="redpoint" v-if="redNum && redNum <= 99">{{
              redNum
            }}</span>
            <span class="redpoint" v-if="redNum > 99">99+</span>
          </div>
        </div>
      </div>
      <!-- 载体基本信息 -->
      <div class="bottom_right" v-if="i == 1">
        <div class="smalltitle">载体基本信息</div>
        <div class="username">
          <span>载体名称：</span> <em>{{ compObj.Name }}</em>
        </div>
        <div class="username">
          <span>载体类型：</span>
          <em> {{ compObj.CateName }}</em>
        </div>
        <div class="username">
          <span>载体级别：</span>
          <em>
            <i v-for="(items, i) in compObj.BuildingLevel" :key="i">{{
              items
            }}</i></em
          >
        </div>
        <div class="username">
          <span>载体面积:</span>
          <em>
            <i v-if="compObj.BuildingArea">{{ compObj.BuildingArea }} m²</i></em
          >
        </div>
        <div class="username">
          <span>孵化面积:</span>
          <em>
            <i v-if="compObj.HatchArea">{{ compObj.HatchArea }} m²</i></em
          >
        </div>
        <div class="username">
          <span>入驻企业：</span>
          <em>
            <i v-if="compObj.CompanyCount">{{ compObj.CompanyCount }} 所</i></em
          >
        </div>
        <div class="username">
          <span>剩余工位：</span>
          <em>
            <i v-if="compObj.OverplusCount"
              >{{ compObj.OverplusCount }} 个</i
            ></em
          >
        </div>
        <div class="username">
          <span>剩余空间：</span>
          <em
            ><i v-if="compObj.RemainSpace">{{ compObj.RemainSpace }} 间</i></em
          >
        </div>

        <div class="username">
          <span>关注领域：</span>
          <em>
            <i v-for="(item, i) in compObj.FollowTag" :key="i">{{ item }}</i>
          </em>
        </div>
        <div class="username">
          <span>提供服务：</span>
          <em
            ><i v-for="(item, i) in compObj.ServeTag" :key="i">{{
              item
            }}</i></em
          >
        </div>
        <div class="username">
          <span>联系人：</span>
          <em>{{ compObj.Contacts }}</em>
        </div>
        <div class="username">
          <span>联系方式：</span>
          <em>{{ compObj.Tel }}</em>
        </div>
        <div class="username">
          <span>地址：</span>
          <em>{{ compObj.Address }}</em>
        </div>
        <div style="padding: 15px" class="mycarrier">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="载体介绍" name="first">
              <div
                class="htmlbox"
                v-html="compObj.Intro"
                ref="content"
                @click="imageEnlargement"
              ></div>
            </el-tab-pane>
            <el-tab-pane label="载体设施" name="second">
              <div
                class="htmlbox"
                v-html="compObj.Facility"
                ref="content"
                @click="imageEnlargement"
              ></div>
            </el-tab-pane>
            <el-tab-pane label="空房出租" name="third">
              <div
                class="htmlbox"
                v-html="compObj.RentOut"
                ref="content"
                @click="imageEnlargement"
              ></div>
            </el-tab-pane>
            <el-tab-pane label="关于我们" name="fourth">
              <div
                class="htmlbox"
                v-html="compObj.AboutMe"
                ref="content"
                @click="imageEnlargement"
              ></div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="inforedint">
          <span @click="gettags(3)">编辑载体基本信息</span>
        </div>
      </div>

      <!-- 修改载体基本信息 -->
      <div class="bottom_right" v-if="i == 3">
        <div class="smalltitle">编辑载体</div>
        <el-form
          :model="compObj"
          :rules="rules"
          ref="compObj"
          class="demo-ruleForm"
        >
          <el-form-item label="载体名称：" prop="Name">
            <el-input v-model="compObj.Name"></el-input>
          </el-form-item>
          <el-form-item label="载体类型：" prop="CateName">
            <el-select
              v-model="compObj.CateName"
              @change="changeType"
              placeholder="请选择"
            >
              <el-option
                v-for="item in companyType"
                :key="item.Name"
                :label="item.Name"
                :value="item.SysID"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="载体级别：" prop="BuildingLevel">
            <el-select
              v-model="compObj.BuildingLevel"
              multiple
              filterable
              allow-create
              default-first-option
              placeholder="请选择载体级别"
            >
              <el-option
                v-for="item in zhaopintypelist"
                :key="item.Name"
                :label="item.Name"
                :value="item.Name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关注领域：" prop="FollowTag">
            <el-select
              v-model="compObj.FollowTag"
              multiple
              filterable
              allow-create
              default-first-option
              placeholder="请选择载体领域"
            >
              <el-option
                v-for="item in FollowTagList"
                :key="item.Name"
                :label="item.Name"
                :value="item.Name"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="提供服务：" prop="ServeTag">
            <el-select
              v-model="compObj.ServeTag"
              multiple
              filterable
              allow-create
              default-first-option
              placeholder="请选择载体服务"
            >
              <el-option
                v-for="item in ServeTagList"
                :key="item.Name"
                :label="item.Name"
                :value="item.Name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="载体面积" prop="BuildingArea">
            <el-input v-model="compObj.BuildingArea">
              <template slot="append">m²</template>
            </el-input>
          </el-form-item>
          <el-form-item label="孵化面积" prop="HatchArea">
            <el-input v-model="compObj.HatchArea">
              <template slot="append">m²</template>
            </el-input>
          </el-form-item>
          <el-form-item label="入驻企业：" prop="CompanyCount">
            <el-input v-model="compObj.CompanyCount">
              <template slot="append">个</template>
            </el-input>
          </el-form-item>
          <el-form-item label="剩余工位：" prop="OverplusCount">
            <el-input v-model="compObj.OverplusCount">
              <template slot="append">个</template>
            </el-input>
          </el-form-item>
          <el-form-item label="剩余空间：" prop="RemainSpace">
            <el-input v-model="compObj.RemainSpace">
              <template slot="append">间</template>
            </el-input>
          </el-form-item>
          <el-form-item label="联系人：" prop="Contacts">
            <el-input v-model="compObj.Contacts"></el-input>
          </el-form-item>
          <el-form-item label="联系方式：" prop="Tel">
            <el-input v-model="compObj.Tel"></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="Address">
            <el-input v-model="compObj.Address"></el-input>
          </el-form-item>

          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="载体介绍" name="first">
              <el-form-item prop="introduce">
                <editor-bar
                  v-model="compObj.Intro"
                  @change="getEditor1"
                  ref="weditor"
                ></editor-bar>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane label="载体设施" name="second">
              <el-form-item prop="introduce">
                <editor-bar
                  v-model="compObj.Facility"
                  @change="getEditor2"
                  ref="weditor"
                ></editor-bar>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane label="空房出租" name="third">
              <el-form-item prop="RentOut">
                <editor-bar
                  v-model="compObj.RentOut"
                  @change="getEditor3"
                  ref="weditor"
                ></editor-bar>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane label="关于我们" name="fourth">
              <el-form-item prop="AboutMe">
                <editor-bar
                  v-model="compObj.AboutMe"
                  @change="getEditor4"
                  ref="weditor"
                ></editor-bar>
              </el-form-item>
            </el-tab-pane>
          </el-tabs>

          <el-form-item>
            <el-button type="primary" @click="geteditZaiti('compObj')"
              >确定</el-button
            >
            <el-button @click="resetForm(1)">取消</el-button>
          </el-form-item>
        </el-form>
      </div>

      <!-- 载体图集 -->
      <div class="bottom_right" v-if="i == 2">
        <div class="smalltitle">载体图片</div>
        <el-form :model="compObj" ref="compObj" class="demo-ruleForm">
          <el-form-item label="载体图片">
            <span slot="label"
              ><span style="color: red; font-size: 12px">*</span> 载体图片<span
                style="color: red; font-size: 12px"
              >
                (建议尺寸 宽300x高200)</span
              ></span
            >
            <template>
              <div v-if="zaitiImg.length > 0">
                <div
                  class="demo-upload-list"
                  :key="index"
                  v-for="(item, index) in zaitiImg"
                >
                  <template style="height: 150px; width: 150px">
                    <div class="demo-upload-list-cover">
                      <img :src="item.url" class="imputImg" />
                      <i
                        class="el-icon-circle-close delimg"
                        @click="RemoveLogo(item)"
                      ></i>
                    </div>
                  </template>
                </div>
              </div>
              <!-- <Upload> -->
              <el-upload
                ref="upload"
                v-if="zaitiImg.length <= 20"
                :before-upload="AddFileLogo"
                type="drag"
                action=""
                style="display: inline-block; width: 100%"
              >
                <div style="width: 100%; height: 58px; line-height: 58px">
                  <i class="el-icon-camera-solid"></i>
                  <!-- <Icon type="ios-camera"
                        size="20"></Icon> -->
                </div>
              </el-upload>
              <!-- </Upload> -->
            </template>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="geteditZaiti('compObj')"
              >确定</el-button
            >
            <el-button @click="resetForm(1)">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 载体设施 -->
      <div class="bottom_right" v-if="i == 4">
        <div class="smalltitle">载体设施信息</div>

        <el-form :model="compObj" ref="compObj" class="demo-ruleForm">
          <el-form-item label="设施图片">
            <span slot="label"
              ><span style="color: red; font-size: 12px">*</span> 设施图片<span
                style="color: red; font-size: 12px"
              >
                (建议尺寸 宽300x高200)</span
              ></span
            >
            <template>
              <div v-if="sheshiImg.length > 0">
                <div
                  class="demo-upload-list"
                  :key="index"
                  v-for="(item, index) in sheshiImg"
                >
                  <template style="height: 150px; width: 150px">
                    <!-- <img class="ssimg" :src="item.url" />
                    <div class="demo-upload-list-cover">
                      <i class="el-icon-delete" @click="RemoveSS(item)"></i>
                    </div> -->

                    <div class="demo-upload-list-cover">
                      <img :src="item.url" class="imputImg" />
                      <!-- <img class="delimg"  src="../assets/img/del.png" alt="" /> -->
                      <i
                        class="el-icon-circle-close delimg"
                        @click="RemoveSS(item)"
                      ></i>
                    </div>
                  </template>
                </div>
              </div>
              <!-- <Upload> -->
              <el-upload
                ref="upload"
                v-if="sheshiImg.length <= 20"
                :before-upload="AddSSFile"
                type="drag"
                action=""
                style="display: inline-block; width: 100%"
              >
                <div style="width: 100%; height: 58px; line-height: 58px">
                  <i class="el-icon-camera-solid"></i>
                  <!-- <Icon type="ios-camera"
                        size="20"></Icon> -->
                </div>
              </el-upload>
              <!-- </Upload> -->
            </template>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="geteditZaitiSS('compObj')"
              >确定</el-button
            >
            <el-button @click="resetForm(1)">取消</el-button>
          </el-form-item>
        </el-form>
      </div>

      <!-- 载体联系人 -->
      <div class="bottom_right" v-if="i == 5">
        <div class="tabboxs">
          <el-table :data="contactsList" style="width: 100%">
            <el-table-column label="企业">
              <template slot-scope="scope">
                <el-popover
                  trigger="hover"
                  v-if="scope.row.CompanyName"
                  placement="top"
                >
                  <p>{{ scope.row.CompanyName }}</p>

                  <div slot="reference" class="name-wrapper">
                    {{ scope.row.CompanyName }}
                  </div>
                </el-popover>
              </template>
            </el-table-column>

            <el-table-column width="250" label="联系方式">
              <template slot-scope="scope">
                <el-popover trigger="hover" placement="top">
                  <p>
                    {{ scope.row.Mobile }}
                    <span v-if="scope.row.Tel"> ({{ scope.row.Tel }})</span>
                  </p>

                  <div slot="reference" class="name-wrapper">
                    {{ scope.row.Mobile }}
                    <span v-if="scope.row.Tel">({{ scope.row.Tel }})</span>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="联系时间">
              <template slot-scope="scope">
                <!-- <i class="el-icon-time"></i> -->
                <span style="margin-left: 10px">{{
                  timeformart.timeFormat(scope.row.CreateOn, 2)
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="反馈">
              <template slot-scope="scope">
                <el-tag v-if="!scope.row.IsHandle" type="warning">
                  未反馈
                </el-tag>
                <el-tag v-else type="success"> 已反馈 </el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  v-if="!scope.row.IsHandle"
                  size="mini"
                  type="primary"
                  @click="getFeedback(6, scope.row)"
                  >填写反馈</el-button
                >

                <el-button
                  v-if="scope.row.IsHandle"
                  size="mini"
                  @click="gettagszhao(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <div class="page">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[5, 10, 15, 20]"
              :page-size="currentSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totoPage"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <!-- 提交反馈 -->

      <div class="bottom_right" v-if="i == 6">
        <div class="smalltitle">填写反馈</div>
        <el-form :model="contactsInfor" class="demo-ruleForm">
          <el-form-item prop="infor">
            <editor-bar
              v-model="contactsInfor.infor"
              @change="getEditorcontact"
              ref="weditor"
            ></editor-bar>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="backContacts">确定</el-button>
            <el-button @click="resetForm(5)">取消</el-button>
          </el-form-item>
        </el-form>
      </div>

      <!-- 反馈详情 -->

      <div class="bottom_right" v-if="i == 7">
        <div class="heard">
          <span @click="gettags(5)"
            ><img src="../assets/img/back.png" alt="" /> 返回</span
          >
        </div>
        <div class="smalltitle">反馈详情</div>

        <!-- <div class="username"> -->
        <!-- <span>反馈时间：</span> -->
        <!-- {{timeformart.timeFormat(contact.HandleOn, 2) }}</div> -->

        <!-- <div class="usertitle" style="float:left; width:100%;padding:10px;box-sizing: border-box;">反馈详情：</div> -->
        <div
          class="htmlbox"
          style="
            float: left;
            width: 100%;
            padding: 10px;
            border: 1px solid rgb(252, 250, 250);
            box-sizing: border-box;
          "
          v-html="contact.HandleResult"
          ref="content"
          @click="imageEnlargement"
        ></div>
      </div>
    </div>

  
  </div>
</template>

<script>
import EditorBar from "../components/wangeditor";

export default {
  components: {
    EditorBar,
  },
  data() {
    var isvalidPhone = function (str) {
      const reg = /^1[0-9]{10}$/;
      return reg.test(str);
    };
    var validPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入电话号码"));
      } else if (!isvalidPhone(value)) {
        callback(new Error("请输入正确的11位手机号码"));
      } else {
        callback();
      }
    };
    return {
      trues: true,
      activeName: "first",
      //   富文本
      editor: null,
      info_: null,
      compObj: {},
      index: 1,
      i: 1,
      dialogVisible: false,
      ruleForms: {
        pass: "",
        checkPass: "",
        age: "",
      },

      ruleForm: {},
      rules: {
        Name: [
          { required: true, message: "请输入载体名称", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "长度在 2 到 50 个字符",
            trigger: "blur",
          },
        ],
        CateName: [
          { required: true, message: "请选择载体类型", trigger: "change" },
        ],

        BuildingLevel: [
          {
            required: true,
            message: "请至少选择一个级别",
            trigger: "change",
          },
        ],
        FollowTag: [
          {
            required: true,
            message: "请至少选择一个领域",
            trigger: "change",
          },
        ],
        ServeTag: [
          {
            required: true,
            message: "请至少选择一个服务",
            trigger: "change",
          },
        ],
        BuildingArea: [
          // { required: true, message: "请填写载体面积", trigger: "blur" },
        ],
        HatchArea: [
          // { required: true, message: "请填写孵化面积", trigger: "blur" },
        ],
        RemainSpace: [
          // { required: true, message: "请填写剩余空间", trigger: "blur" },
        ],
        CompanyCount: [
          // { required: true, message: "请填写入驻企业个数", trigger: "blur" },
        ],
        OverplusCount: [
          // { required: true, message: "请填写剩余工位", trigger: "blur" },
        ],
        Contacts: [
          { required: true, message: "请填写联系人", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
        // 联系电话
        Tel: [{ required: true, trigger: "blur", validator: validPhone }],
        Address: [{ required: true, message: "请填地址", trigger: "blur" }],
      },
      content: "",
      // 载体类型
      companyType: [],
      zhaopintypelist: [],
      zaitiImg: [],
      DeleteImgSysID: "",
      sheshiImg: [],
      dialogImageUrl: "",
      UserObj: {},
      isCarre: "",
      FollowTagList: [],
      ServeTagList: [],
      contactsList: [],
      currentPage: 1,
      currentSize: 5,
      totoPage: null,
      redNum: 0,
      SysID: "",
      contactsInfor: {
        infor: "",
      },
      contact: null,
      imgArr: [], //存放所有的图片地址
      options: {
        title: false, //预览组件-不显示标题
      },
    };
  },

  methods: {
    inited(viewer) {
      this.$viewer = viewer;
    },

    imageEnlargement(e) {
      if (e.target.nodeName == "IMG") {
        //判断点击富文本内容为img图片
        console.log(e.target.currentSrc);
        this.imgArr[0] = e.target.currentSrc;
        console.log(this.imgArr);

          this.$viewerApi({
          images: this.imgArr
        })
      } else {
        console.log("点击内容不为img");
      }
    },

    // 反馈详情
    gettagszhao(item) {
      this.i = 7;
      console.log(item);
      this.contact = item;
    },
    // 提交反馈
    backContacts() {
      let that = this;
      let SysID = that.SysID;
      let infor = that.contactsInfor.infor;
      if (!infor) {
        that.$message.error("请填写反馈内容");
        return;
      }

      that
        .$post("/api/Contact/HandleEdit", {
          SysID: SysID,

          HandleResult: infor,
        })
        .then(function (data) {
          console.log(data);
          if (data.Result) {
            that.contactsInfor.infor = "";
            window.localStorage.setItem("isconcat", "yes");
            // that.getContacts()
            document.location.reload();

            // setTimeout(() => {
            //    that.i = 5
            // }, 1000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 联系数量
    getContactsNum() {
      let that = this;
      let compObj = that.compObj;
      console.log(compObj);
      that
        .$post("/api/Contact/ContactCount", {
          IsHandle: false,

          MainSysID: compObj.SysID,
        })
        .then(function (data) {
          console.log(data);
          if (data.Result) {
            that.redNum = data.Obj;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 联系载体列表
    getContacts() {
      let that = this;
      let compObj = that.compObj;
      let currentPage = that.currentPage;
      let currentSize = that.currentSize;
      that
        .$post("/api/Contact/List", {
          Type: 0,
          Sort: "CreateOn Desc",
          MainSysID: compObj.SysID,
          PageIndex: currentPage,
          PageSize: currentSize,
        })
        .then(function (data) {
          console.log(data);
          // that.getContactsNum();
          if (data.Result) {
            that.contactsList = data.Obj;
            that.totoPage = data.RecordCount;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 分页
    handleSizeChange(a) {
      console.log(a);
      this.currentSize = a;
      this.getContacts();
    },
    handleCurrentChange(b) {
      console.log(b);
      this.currentPage = b;
      this.getContacts();
    },
    changeType(val) {
      console.log(val);
      this.compObj.CateSysID = val;
    },
    resetForm(i) {
      this.i = i;
      this.DeleteImgSysID = "";
      this.contactsInfor.infor = "";
      this.getnewsList();
    },
    //附件列表
    LoadFiles(sysid) {
      if (sysid == "") return;
      this.loading = true;
      let that = this;
      that
        .$post("/api/File/List", {
          MainSysID: sysid,
        })
        .then(function (res) {
          let List = [];
          that.zaitiImg = [];
          that.sheshiImg = [];
          List = res.Obj;
          List.forEach((i) => {
            if (i.Mark == "图片") {
              that.zaitiImg.push({
                name: i["SourceName"] + "." + i["Extension"],
                url: that.urlOA + i["URL"].substr(1),
                size: -1,
                SysID: i.SysID,
              });
            } else if (i.Mark == "设施图片") {
              that.sheshiImg.push({
                name: i["SourceName"] + "." + i["Extension"],
                url: that.urlOA + i["URL"].substr(1),
                size: -1,
                SysID: i.SysID,
              });
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // LoadSSFiles(sysid) {
    //   if (sysid == "") return;
    //   this.loading = true;
    //   let that = this;
    //   that
    //     .$post("/api/Building/SSList", {
    //       MainSysID: sysid,
    //     })
    //     .then(function (res) {
    //       let List = [];
    //       that.sheshiImg = [];
    //       List = res.Obj;

    //       List.forEach((i) => {
    //         that.sheshiImg.push({
    //           name: i["SourceName"] + "." + i["Extension"],
    //           url: that.urlOA + i["URL"].substr(1),
    //           size: -1,
    //           SysID: i.SysID,
    //         });
    //       });
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    //删除logo
    RemoveLogo(Obj) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (Obj.size === -1) {
            this.zaitiImg = this.zaitiImg.filter((v) => {
              return v != Obj;
            });
            this.DeleteImgSysID += "," + Obj.SysID;
          } else {
            this.zaitiImg = this.zaitiImg.filter((v) => {
              return v != Obj;
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    RemoveSS(Obj) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (Obj.size === -1) {
            this.sheshiImg = this.sheshiImg.filter((v) => {
              return v != Obj;
            });
            this.DeleteImgSysID = this.DeleteImgSysID + "," + Obj.SysID;
          } else {
            this.sheshiImg = this.sheshiImg.filter((v) => {
              return v != Obj;
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
      // if (this.loading) return;
    },
    //上传logo附件
    AddFileLogo(file) {
      let fileName = file.name;
      let index = fileName.lastIndexOf(".");
      if (index > 0) {
        let suffixName = fileName.substring(index + 1).toLowerCase();
        if (
          suffixName == "jpg" ||
          suffixName == "jpeg" ||
          suffixName == "png"
        ) {
          this.file = file;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const _base64 = reader.result;
            file.url = _base64;
            this.zaitiImg = this.zaitiImg.concat(file);
          };
        } else {
          this.$Message.warning("上传附件格式有误,请重新上传!");
        }
      }
      return false;
    },

    AddSSFile(file) {
      let fileName = file.name;
      let index = fileName.lastIndexOf(".");
      if (index > 0) {
        let suffixName = fileName.substring(index + 1).toLowerCase();
        if (
          suffixName == "jpg" ||
          suffixName == "jpeg" ||
          suffixName == "png"
        ) {
          this.file = file;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const _base64 = reader.result;
            file.url = _base64;
            this.sheshiImg = this.sheshiImg.concat(file);
          };
        } else {
          this.$Message.warning("上传附件格式有误,请重新上传!");
        }
      }
      return false;
    },
    getindex() {
      window.localStorage.setItem("index", "1");
      this.$router.push("/");
    },

    AddFile(event) {
      let that = this;
      var file = event.target.files;
      console.log(file);
      file.forEach((v) => {
        let fileName = v.name;
        let index = fileName.lastIndexOf(".");
        if (index > 0) {
          let suffixName = fileName.substring(index + 1).toLowerCase();
          if (
            suffixName == "jpg" ||
            suffixName == "jpeg" ||
            suffixName == "png"
          ) {
            this.zaitiImg = this.zaitiImg.concat(v);
            console.log(v);
            setTimeout(() => {
              that.getimg();
            }, 1000);
          } else {
            this.$Message.warning("上传附件格式有误,请重新上传!");
          }
        }
      });
    },

    handleClick() {},
    // 获取企业信息
    getnewsList() {
      let that = this;
      let Account = window.localStorage.getItem("Account");
      that
        .$post("/api/UserInfo/ObjAccount", {
          Account: Account,
        })
        .then(function (data) {
          console.log(data);
          let company = data.Obj.BuildingObj;
          let UserObj = data.Obj.UserObj;
          that.UserObj = UserObj;
          if (
            company.BuildingLevel &&
            company.BuildingLevel.indexOf(",") > -1
          ) {
            company.BuildingLevel = company.BuildingLevel.split(",");
          } else if (
            company.BuildingLevel &&
            company.BuildingLevel.indexOf(",") == -1
          ) {
            let arr = [];
            arr.push(company.BuildingLevel);
            company.BuildingLevel = arr;
          }
          if (company.FollowTag && company.FollowTag.indexOf(",") > -1) {
            company.FollowTag = company.FollowTag.split(",");
          } else if (
            company.FollowTag &&
            company.FollowTag.indexOf(",") == -1
          ) {
            let arr = [];
            arr.push(company.FollowTag);
            company.FollowTag = arr;
          }
          if (company.ServeTag && company.ServeTag.indexOf(",") > -1) {
            company.ServeTag = company.ServeTag.split(",");
          } else if (company.ServeTag && company.ServeTag.indexOf(",") == -1) {
            let arr = [];
            arr.push(company.ServeTag);
            company.ServeTag = arr;
          }
          // 图片处理
          // company.Logo = that.urlOA + company.Logo.substr(1);
          that.compObj = company;
          that.getContactsNum();
          that.getContacts();
          that.LoadFiles(company.SysID);
          //that.LoadSSFiles(company.SysID);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 载体类型
    gettype() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          Type: "LoadBuild",
          Sort: "Sort asc",
          State: true,
        })
        .then(function (data) {
          console.log(data);
          let list = data.Obj;
          console.log(list);
          that.companyType = list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 载体级别
    getzhaopintype() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          Type: "CarrierLevel",
          Sort: "Sort asc",
          State: true,
        })
        .then(function (data) {
          console.log(data);
          let list = data.Obj;
          console.log(list);
          that.zhaopintypelist = list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 关注领域
    getFollowTagList() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          Type: "FocusAreas",
          Sort: "Sort asc",
          State: true,
        })
        .then(function (data) {
          console.log(data);
          let list = data.Obj;
          console.log(list);
          that.FollowTagList = list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 关注领域
    getServeTagList() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          Type: "BuildingService",
          Sort: "Sort asc",
          State: true,
        })
        .then(function (data) {
          console.log(data);
          let list = data.Obj;
          console.log(list);
          that.ServeTagList = list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getEditor1(value) {
      console.log(value);
      this.compObj.Intro = value;
    },
    getEditor2(value) {
      console.log(value);
      this.compObj.Facility = value;
    },
    getEditor3(value) {
      console.log(value);
      this.compObj.RentOut = value;
    },
    getEditorcontact(value) {
      console.log(value);
      this.contactsInfor.infor = value;
    },
    getEditor4(value) {
      console.log(value);
      this.compObj.AboutMe = value;
    },
    // 编辑载体/api/Building/Edit
    geteditZaiti(formName) {
      let that = this;
      let ruleForm = that.compObj;
      console.log(ruleForm.BuildingLevel);

      that.$refs[formName].validate((valid) => {
        if (valid) {
          let formData = new FormData();
          let zaitiImg = that.zaitiImg;
          if (zaitiImg.length == 0) {
            that.$message.error("请上传载体图片");
            return;
          }
          if (ruleForm.BuildingLevel.length > 1) {
            ruleForm.BuildingLevel = ruleForm.BuildingLevel.join(",");
          } else if (ruleForm.BuildingLevel.length == 1) {
            ruleForm.BuildingLevel = ruleForm.BuildingLevel[0];
          }
          if (ruleForm.FollowTag.length > 1) {
            ruleForm.FollowTag = ruleForm.FollowTag.join(",");
          } else if (ruleForm.FollowTag.length == 1) {
            ruleForm.FollowTag = ruleForm.FollowTag[0];
          }
          if (ruleForm.ServeTag.length > 1) {
            ruleForm.ServeTag = ruleForm.ServeTag.join(",");
          } else if (ruleForm.ServeTag.length == 1) {
            ruleForm.ServeTag = ruleForm.ServeTag[0];
          }
          zaitiImg.forEach((file) => {
            formData.append("图片", file);
          });
          formData.append("DeleteImgSysID", this.DeleteImgSysID);
          formData.append("Obj", JSON.stringify(ruleForm));
          that.$post("/api/Building/Edit", formData).then(function (data) {
            console.log(data);
            if (data.Result) {
              that.$message.success("编辑载体信息成功");
              that.zaitiImg = [];
              that.getnewsList();
              let i = that.i;
              if (i == 3) {
                that.i = 1;
              }
            } else {
              that.$message.error(data.Msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // 编辑载体/api/Building/Edit
    geteditZaitiSS(formName) {
      let that = this;
      let ruleForm = that.compObj;

      that.$refs[formName].validate((valid) => {
        if (valid) {
          let formData = new FormData();
          let sheshiImg = that.sheshiImg;
          if (sheshiImg.length == 0) {
            that.$message.error("请上传载体设施图片");
            return;
          }
          if (ruleForm.BuildingLevel.length > 1) {
            ruleForm.BuildingLevel = ruleForm.BuildingLevel.join(",");
          } else {
            ruleForm.BuildingLevel = ruleForm.BuildingLevel[0];
          }
          if (ruleForm.FollowTag.length > 1) {
            ruleForm.FollowTag = ruleForm.FollowTag.join(",");
          } else {
            ruleForm.FollowTag = ruleForm.FollowTag[0];
          }
          if (ruleForm.ServeTag.length > 1) {
            ruleForm.ServeTag = ruleForm.ServeTag.join(",");
          } else {
            ruleForm.ServeTag = ruleForm.ServeTag[0];
          }
          sheshiImg.forEach((file) => {
            formData.append("设施图片", file);
          });
          formData.append("DeleteImgSysID", that.DeleteImgSysID);
          formData.append("Obj", JSON.stringify(ruleForm));

          that.$post("/api/Building/Edit", formData).then(function (data) {
            console.log(data);
            if (data.Result) {
              that.$message.success("编辑载体信息成功");
              that.zaitiImg = [];
              that.getnewsList();
            } else {
              that.$message.error(data.Msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getperson() {
      this.$router.push("/personalCenter");
    },
    getnews() {
      this.$router.push("/newsCenter");
    },
    getcompany() {
      this.$router.push("/centerHome");
    },
    gettags(i) {
      this.i = i;
    },

    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      console.log(file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforupload(e) {
      console.log(e, 111111111111);
      let url = this.urlOA + e.data[0].url.substr(1);
      console.log(url);
    },
    getFeedback(index, row) {
      let that = this;
      that.i = index;
      that.SysID = row.SysID;
    },
  },

  mounted() {
    this.getnewsList();
    // 载体类型
    this.gettype();
    this.getzhaopintype();
    this.getFollowTagList();
    this.getServeTagList();
    let isconcat = window.localStorage.getItem("isconcat");
    if (isconcat) {
      this.i = 5;
      window.localStorage.removeItem("isconcat");
    }
    // this.getContactsNum()
  },
};
</script>


<style scoped  lang="scss">
.heard {
  position: relative;
  height: 30px;
  width: 100%;
  span {
    cursor: pointer;
    position: absolute;
    height: 30px;
    width: 100px;
    border-radius: 3px;
    color: #1890ff;
    font-size: 14px;
    line-height: 32px;
    background-color: #fff;
    vertical-align: middle;
    img {
      display: inline-block;
      height: 30px;
      width: 30px;
      // vertical-align:middle;
      vertical-align: middle;
    }
  }
}
.page {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mycarrier {
  float: left;
  width: 100%;
  box-sizing: border-box;
}
.ssimg {
  max-width: 100%;
}
.imgbox {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  overflow: hidden;
  .del_btn {
    float: left;
    position: relative;
    height: 200px;
    width: 300px;
    margin-left: 20px;

    .btnbox {
      position: absolute;
      top: 0;
      height: 200px;
      width: 300px;
      background-color: rgba(0, 0, 0, 0.5);
      cursor: pointer;
      z-index: 999999;
    }

    img {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 9999999;
    }
    img:hover {
      z-index: 99;
    }
  }
}
.htmlbox ::v-deep img {
  max-width: 100%;
}
.htmlbox ::v-deep p {
  line-height: 30px;
  font-size: 15px;
  color: #555;
}
.htmlbox ::v-deep dt {
  line-height: 40px;
  font-weight: 600;
  font-size: 15px;
  color: #555;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.textbox {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #f6f6f6;
}
.topbox {
  width: 100%;
  background-color: #fff;
  height: 100px;
  margin-top: 30px;
}
.topboxinfor {
  width: 1200px;
  height: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
  // background-color: aquamarine;
}
.topboxinfor img {
  float: left;
  height: 60px;
  margin-top: 20px;
  margin-right: 40px;
}

.bottombox {
  width: 1200px;
  min-height: 600px;
  margin-left: 50%;
  margin-top: 20px;
  transform: translateX(-50%);
  // background-color: aquamarine;
}
.bottom_left {
  float: left;
  // background-color: red;
  width: 300px;
  min-height: 600px;
}
.bottom_right {
  float: left;
  width: 880px;
  margin-left: 20px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #eee;
  min-height: 600px;
  box-sizing: border-box;
  overflow: hidden;
}
.tabbox {
  width: 100%;
  border: 1px solid #eee;
  background-color: #fff;
  padding-bottom: 40px;
  box-sizing: border-box;
}
.tabtitle {
  padding-left: 30px;
  box-sizing: border-box;
  height: 50px;
  line-height: 55px;
  font-size: 16px;
}
.tabbtn {
  position: relative;
  height: 35px;
  line-height: 35px;
  padding-left: 40px;
  box-sizing: border-box;
  font-size: 14px;
  color: #555;
  cursor: pointer;
  .redpoint {
    position: absolute;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background-color: red;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    color: #fff;

    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.active {
  border-left: 3px solid #409eff;
  background-color: #f6f6f6;
}
.active1 {
  background-color: #e9e9e9;
}
.huanying {
  height: 60px;
  line-height: 30px;
  padding: 15px;
  width: 100%;
  background-color: #d9edf7;
  box-sizing: border-box;
  font-size: 14px;
  color: #666;
}
.smalltitle {
  height: 36px;
  line-height: 6px;
  font-size: 14px;
  background-color: #f1f1f1;
  margin-top: 15px;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.userinforbox {
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 15px;
}
.username {
  float: left;
  display: flex;
  align-items: center;
  padding: 15px;
  // height: 60px;
  line-height: 30px;
  font-size: 14px;
  color: #000;
  box-sizing: border-box;
  min-width: 50%;
  max-width: 100%;
  // margin-right: 200px;
  span {
    display: inline-block;
    // line-height: 30px;

    width: 120px;
    color: #666;
    // background-color: #D9EDF7;
  }
  em {
    font-style: normal;
    overflow: hidden;

    i {
      float: left;
      font-style: normal;
      margin-right: 10px;
      margin-bottom: 5px;
      margin-top: 5px;
      background-color: #ff6633;
      border-radius: 2px;
      padding: 0 10px;
      color: #fff;
    }
  }
}
.userlogo {
  height: 100px;
  // font-weight: 700;

  line-height: 100px;
  font-size: 16px;

  span {
    display: inline-block;
    width: 100px;
    font-size: 14px;
    //  padding: 15px;
    color: #666;
  }
  img {
    height: 100px;
    // width: 80px;
    vertical-align: middle;
    margin-right: 30px;
  }
}
.inforedint {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  background-color: #f1f1f1;
  span {
    cursor: pointer;
    background-color: #1890ff;
    padding: 10px 30px;
    color: #fff;
    font-size: 15px;
    border-radius: 3px;
  }
}

.password {
  display: flex;
  align-items: center;
  // height: 80px;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}
.left {
  flex: 6;
  .left_title {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
    padding-left: 15px;
    box-sizing: border-box;
    color: #555;
  }
  .left_text {
    color: #555;
    line-height: 30px;
    font-size: 14px;
    padding-left: 15px;
    box-sizing: border-box;
  }
}
.right {
  flex: 1;

  span {
    background-color: #1890ff;
    cursor: pointer;
    padding: 3px 20px;
    color: #fff;
    font-size: 15px;
    border-radius: 3px;
  }
}

.bottom_right::v-deep .el-form-item__content {
  width: 80%;
  text-align: left;
  margin-top: 60px;
  img {
    // float: left;
    // padding-bottom: 30px;
  }
  .delimg {
    float: left;
    margin-top: -15px;
    margin-left: -15px;
    color: #fff;
    font-size: 30px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    z-index: 9999999999;
    border-radius: 50%;
    background-color: red;
  }
}
.el-icon-camera-solid:before {
  font-size: 24px;
}

@media (max-width: 500px) {
  .bottom_right {
    overflow: auto !important;
  }
  .tabboxs {
    width: 900px !important;
  }
  .mycarrier {
    float: left;
    width: 100%;
    box-sizing: border-box;
  }
  .ssimg {
    max-width: 100%;
  }
  .imgbox {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    overflow: hidden;
    .del_btn {
      float: left;
      position: relative;
      height: 200px;
      width: 300px;
      margin-left: 20px;

      .btnbox {
        position: absolute;
        top: 0;
        height: 200px;
        width: 300px;
        background-color: rgba(0, 0, 0, 0.5);
        cursor: pointer;
        z-index: 999999;
      }

      img {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 9999999;
      }
      img:hover {
        z-index: 99;
      }
    }
  }
  .htmlbox ::v-deep img {
    width: 100%;
  }
  .htmlbox ::v-deep p {
    line-height: 30px;
    font-size: 15px;
    color: #555;
  }
  .htmlbox ::v-deep dt {
    line-height: 40px;
    font-weight: 600;
    font-size: 15px;
    color: #555;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .textbox {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #f6f6f6;
  }
  .topbox {
    width: 100%;
    background-color: #fff;
    height: 100px;
    margin-top: 30px;
  }
  .topboxinfor {
    width: 1200px;
    height: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
    // background-color: aquamarine;
  }
  .topboxinfor img {
    float: left;
    height: 60px;
    margin-top: 20px;
    margin-right: 40px;
  }

  .bottombox {
    width: 100%;
    min-height: 600px;
    margin-left: 50%;
    margin-top: 20px;
    transform: translateX(-50%);
    // background-color: aquamarine;
  }
  .bottom_left {
    float: left;
    // background-color: red;
    width: 100%;
    min-height: 60px;
  }
  .bottom_right {
    float: left;
    width: 100%;
    margin-left: 0;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #eee;
    min-height: 600px;
    box-sizing: border-box;
    overflow: hidden;
  }
  .tabbox {
    width: 100%;
    border: 1px solid #eee;
    background-color: #fff;
    padding-bottom: 10px;
    box-sizing: border-box;
    overflow: hidden;
  }
  .tabtitle {
    display: none;
    padding-left: 30px;
    box-sizing: border-box;
    height: 50px;
    line-height: 55px;
    font-size: 16px;
  }
  .tabbtn {
    float: left;
    text-align: center;
    height: 40px;
    line-height: 40px;
    width: 33%;
    padding-left: 0;
    box-sizing: border-box;
    font-size: 14px;
    color: #555;
    cursor: pointer;
    .redpoint {
      position: absolute;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      background-color: red;
      font-size: 10px;
      line-height: 18px;
      text-align: center;
      color: #fff;
      right: 0;
      top: 0;
      transform: translateY(0);
    }
  }
  .active {
    background-color: #409eff;
    color: #fff;
  }
  .active1 {
    background-color: #e9e9e9;
  }
  .huanying {
    height: 60px;
    line-height: 30px;
    padding: 15px;
    width: 100%;
    background-color: #d9edf7;
    box-sizing: border-box;
    font-size: 14px;
    color: #666;
  }
  .smalltitle {
    height: 36px;
    line-height: 6px;
    font-size: 14px;
    background-color: #f1f1f1;
    margin-top: 15px;
    padding: 15px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  .userinforbox {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    padding: 15px;
  }
  .username {
    float: left;
    display: flex;
    align-items: center;
    padding: 15px;
    // height: 60px;
    line-height: 30px;
    font-size: 14px;
    color: #000;
    box-sizing: border-box;
    width: 100%;
    // margin-right: 200px;
    span {
      display: inline-block;
      // line-height: 30px;
      flex: 3;
      width: 120px;
      color: #666;
      // background-color: #D9EDF7;
    }
    em {
      font-style: normal;
      overflow: hidden;
      flex: 7;

      i {
        float: left;
        font-style: normal;
        margin-right: 10px;
        margin-bottom: 5px;
        margin-top: 5px;
        background-color: #ff6633;
        border-radius: 2px;
        padding: 0 10px;
        color: #fff;
      }
    }
  }
  .userlogo {
    height: 100px;
    // font-weight: 700;

    line-height: 100px;
    font-size: 16px;

    span {
      display: inline-block;
      width: 100px;
      font-size: 14px;
      //  padding: 15px;
      color: #666;
    }
    img {
      height: 100px;
      // width: 80px;
      vertical-align: middle;
      margin-right: 30px;
    }
  }
  .inforedint {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    background-color: #f1f1f1;
    span {
      cursor: pointer;
      background-color: #1890ff;
      padding: 10px 30px;
      color: #fff;
      font-size: 15px;
      border-radius: 3px;
    }
  }

  .password {
    display: flex;
    align-items: center;
    // height: 80px;
    padding: 15px 0;
    border-bottom: 1px solid #eee;
  }
  .left {
    flex: 6;
    .left_title {
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      font-weight: 600;
      padding-left: 15px;
      box-sizing: border-box;
      color: #555;
    }
    .left_text {
      color: #555;
      line-height: 30px;
      font-size: 14px;
      padding-left: 15px;
      box-sizing: border-box;
    }
  }
  .right {
    flex: 1;

    span {
      background-color: #1890ff;
      cursor: pointer;
      padding: 3px 20px;
      color: #fff;
      font-size: 15px;
      border-radius: 3px;
    }
  }

  .bottom_right::v-deep .el-tabs__item {
    padding: 0 14px;
  }
  .bottom_right::v-deep .el-form-item__content {
    width: 100%;
    text-align: left;
    margin-top: 60px;
    img {
      // float: left;
      // padding-bottom: 30px;
    }
    .delimg {
      float: left;
      margin-top: -15px;
      margin-left: -15px;
      color: #fff;
      font-size: 30px;
      cursor: pointer;
      height: 30px;
      width: 30px;
      z-index: 9999999999;
      border-radius: 50%;
      background-color: red;
    }
  }
  .el-icon-camera-solid:before {
    font-size: 24px;
  }
}
</style>